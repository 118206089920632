import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';

import { ShellComponent } from './components/shell/shell.component';
import { MaterialModule } from '../material.module';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MenuComponent } from './components/menu/menu.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';

@NgModule({
  declarations: [
    ShellComponent,
    MenuItemComponent,
    MenuComponent,
    LanguageSelectorComponent],
  imports: [
    MaterialModule,
    AppRoutingModule
  ],
  exports: [ShellComponent]
})
export class MainModule { }