import { DtoGitInfo } from "./dto-git-info";

export interface DtoMenu {
  language: string;
  items: Array<DtoMenuItem>;
  gitInfo?: DtoGitInfo;
}

export interface DtoMenuItem {
  sequence: number;
  label: string;
  queryParams?: string;
  routerLink: string;
  isHidden?: boolean;
  icon?: string;
  children?: Array<DtoMenuItem>;
}