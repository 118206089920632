import { Language } from "./language.enum";

export class Constants {
  public static defaultLanguage = Language.de;
  public static languageHeaderName = 'Preferred-Language';
  public static filePrefix = 'file://';
}



