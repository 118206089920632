import { Language } from "../types/language.enum";

export interface DtoSupportedLanguage {
  language: Language;
  image: string;
  label: Record<Language, string>;
}

export interface DtoSupportedLanguages {
  defaultLanguage: Language;
  languages: Array<DtoSupportedLanguage>;
}