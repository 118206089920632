import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '@common';
// import { Constants } from '@common';
import { environment } from 'curriculum-site/src/environments/environment';
import { Observable } from 'rxjs';
import { CookiesService } from './cookies.service';
import { I18nService } from './i18n.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`. Considering them as API-calls
 * Adds a prefereed language header in case we do not use cookies
 */
@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {

  private i18Service: I18nService;
  private cookiesService: CookiesService;

  public constructor(i18Service: I18nService, cookiesService: CookiesService) {
    this.i18Service = i18Service;
    this.cookiesService = cookiesService;
  }

  //#region public methods ----------------------------------------------------
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({ url: environment.apiServerUrl + request.url });
    }
    // if we do not have a cookie language: add the preferred language as header
    if (!this.cookiesService.getCookieByName(CookiesService.COOKIE_NAME_LANGUAGE)) {
      request = request.clone({ headers: request.headers.set(Constants.languageHeaderName, this.i18Service.currentLanguage)})
    }
    return next.handle(request);
  }
  //#endregion
}