<div *ngIf="mailIsAvailable === false && formData">
  <hr>
  {{formData.notAvailable}}
</div>
<ng-container *ngIf="mailIsAvailable === true && formData">
  <div mat-dialog-content [formGroup]="formGroup" class="contact-form-container">
    <div class="form-div">
      <mat-form-field [class]="(isHandset | async) ? 'small-width' : 'large-width contact-form-field'">
        <!-- <mat-label>Ihre E-Mail-Adresse</mat-label> -->
        <mat-label>{{formData.emailLabel}}</mat-label>
        <input matInput type="email" placeholder="{{formData.emailPlaceholder}}" formControlName="sender">
        <mat-error *ngIf="senderControl.hasError('email') && !senderControl.hasError('required')">
          {{formData.emailInvalidError}}
        </mat-error>
        <mat-error *ngIf="senderControl.hasError('required')">
          {{formData.emailMandatoryError}}
        </mat-error>
      </mat-form-field>

      <mat-form-field [class]="(isHandset | async) ? 'small-width' : 'large-width contact-form-field'">
        <mat-label>{{formData.emailRepeatLabel}}</mat-label>
        <input matInput type="email" placeholder="{{formData.emailRepeatPlaceholder}}" formControlName="senderRepeat">
        <mat-error *ngIf="senderRepeatControl.hasError('email') && !senderRepeatControl.hasError('required')">
          {{formData.emailRepeatInvalidError}}
        </mat-error>
        <mat-error *ngIf="senderRepeatControl.hasError('required')">
          {{formData.emailRepeatMandatoryError}}
        </mat-error>
      </mat-form-field>
      <mat-error *ngIf="formGroup.hasError('noMatch') && senderControl.valid && senderRepeatControl.valid" class="group-error">
        {{formData.emailsDifferentError}}
      </mat-error>
    </div>

    <div  class="form-div">
      <label id="salutation-label">{{formData.salutationLabel}} *</label>
      <mat-radio-group aria-labelledby="salutation-label"
        [class]="(isHandset | async) ? 'salutation-radio-group-small': 'salutation-radio-group-large'"
        formControlName="salutation">
        <mat-radio-button color="primary" class="salutation-radio-button" *ngFor="let salutation of salutationValues"
          [value]="salutation">
          {{salutation}}
        </mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="salutationControl.hasError('required') && salutationControl.touched">
        {{formData.salutationMandatoryError}}
      </mat-error>
    </div>

    <div class="form-div">
      <mat-form-field [class]="(isHandset | async) ? 'small-width' : 'large-width contact-form-field'">
        <mat-label>{{formData.firstNameLabel}}</mat-label>
        <input matInput placeholder="{{formData.firstNamePlaceholder}}" formControlName="firstName">
      </mat-form-field>

      <mat-form-field [class]="(isHandset | async) ? 'small-width' : 'large-width contact-form-field'">
        <mat-label>{{formData.lastNameLabel}}</mat-label>
        <input matInput placeholder="{{formData.lastNamePlaceholder}}" formControlName="lastName">
      </mat-form-field>
    </div>

    <div class="form-div">
      <mat-slide-toggle color="primary" formControlName="perDu">{{formData.perDuLabel}}
      </mat-slide-toggle>
    </div>

    <div class="form-div">
      <mat-form-field class="full-width">
        <mat-label>{{formData.subjectLabel}}</mat-label>
        <input matInput formControlName="subject">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>{{formData.contentLabel}}</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="15"
          formControlName="content"></textarea>
      </mat-form-field>
      <mat-error *ngIf="contentControl.hasError('minlength')">
        {{formData.contentLength}}
      </mat-error>
    </div>

    <div class="honey">
      <mat-form-field>
        <mat-label>Telefon</mat-label>
        <input matInput type="tel" formControlName="phoneNumber">
      </mat-form-field>
    </div>

    <div *ngIf="error" class="error"  [innerHtml]="error | markdown">
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button (click)="clear()">{{formData.buttonClearLabel}}</button>
    <button mat-stroked-button (click)="send()" [disabled]="formGroup.invalid">{{formData.buttonSendLabel}}</button>
  </div>
</ng-container>