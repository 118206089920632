export type FormName = 'cookie-dialog' | 'contact-form' | 'clear-cookie-form';
export type ClearCookieFormFields = 'clearButtonLabel' | 'noCookieText' | 'cookieText' | 'afterClearMessage';
export type CookieDialogFields = 'title' | 'okButtonLabel' | 'cancelButtonLabel' | 'content';
export type ContactFormFields =
  'notAvailable' |
  'emailLabel' |
  'emailPlaceholder' |
  'emailMandatoryError' |
  'emailInvalidError' |
  'emailRepeatLabel' |
  'emailRepeatPlaceholder' |
  'emailRepeatMandatoryError' |
  'emailRepeatInvalidError' |
  'emailsDifferentError' |
  'salutationLabel' |
  'salutationValues' |
  'otherSalutationText' |
  'otherSalutationMandatoryError' |
  'salutationMandatoryError' |
  'firstNameLabel' |
  'firstNamePlaceholder' |
  'lastNameLabel' |
  'lastNamePlaceholder' |
  'perDuLabel' |
  'subjectLabel' |
  'contentLabel' |
  'contentLength' |
  'buttonClearLabel' |
  'buttonSendLabel';