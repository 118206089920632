<ng-container *ngIf="page">
  <ng-container *ngFor="let block of page.blocks">
    <mat-card>
      <ng-container *ngIf="block.title">
        <mat-card-title>{{block.title}}</mat-card-title>
        <hr>
      </ng-container>
      <mat-card-subtitle>
        <ng-container *ngIf="block.wordDefinition">
          <app-content-word-definition [wordDefinition]="block.wordDefinition" class="word-definition">
          </app-content-word-definition>
          <hr>
        </ng-container>
      </mat-card-subtitle>
      <mat-card-content>
        <app-content-content [content]="block.content"></app-content-content>
        <!-- start form section -->
        <ng-container *ngIf="block.form === 'contact-form'">
          <app-contact-form></app-contact-form>
        </ng-container>
        <ng-container *ngIf="block.form === 'clear-cookie-form'">
          <app-clear-cookie-form></app-clear-cookie-form>
        </ng-container>
        <!-- end form section -->
      </mat-card-content>
      <mat-card-footer>
        <ng-container>
          <hr>
          <app-content-quotation [quotation]="block.quotation"></app-content-quotation>
        </ng-container>
      </mat-card-footer>
    </mat-card>
  </ng-container>
</ng-container>