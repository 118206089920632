import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { DtoPage } from '@common';
import { ContentService } from 'curriculum-site/src/app/core/services/content.service';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss']
})
export class ContentPageComponent implements OnInit {
  //#region private properties ------------------------------------------------
  private readonly route: ActivatedRoute;
  private readonly contentService: ContentService;
  private readonly titleService: Title;
  //#endregion

  //#region public properties -------------------------------------------------
  public page!: DtoPage;
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  public constructor(route: ActivatedRoute, titleService: Title, contentService: ContentService) {
    this.route = route;
    this.titleService = titleService;
    this.contentService = contentService;
  }

  public ngOnInit(): void {
    this.contentService.getContent.subscribe((page: DtoPage) => {
      this.page = page;
      const newTitle = page.windowTitle ?
        `Der e-coach: ${page.windowTitle}` :
        'Der e-coach'
      this.titleService.setTitle(newTitle);
    });

    this.route.queryParams.subscribe((params: Params) => {
      const content = params['content'] || 'home';
      this.contentService.currentPath = content;
    });
  }
  //#endregion
}
