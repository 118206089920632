import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  //#region private properties ------------------------------------------------
  private titleService: Title;
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  public constructor(titleService: Title) {
    this.titleService = titleService;
  }

  public ngOnInit(): void {
    this.titleService.setTitle('Der e-coach');
  }
  //#endregion
}
