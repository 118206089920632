import { Component, Input } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DtoMenuItem } from '@common';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent {

  //#region @Input/@Output/@ViewChild -----------------------------------------
  @Input('item') item!: DtoMenuItem;
  //#endregion

  //#region private properteis ------------------------------------------------
  private router: Router;
  //#endregion

  //#region getters/setters ---------------------------------------------------
  constructor(router: Router) {
    this.router = router;
  }
  //#endregion

  //#region UI triggered methods ----------------------------------------------
  menuClick(routerLink: string, queryParams: Params): void {
    this.router.navigate([routerLink], { queryParams: queryParams });
  }
  //#endregion
}
