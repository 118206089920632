/**
 * The test environment runs on test.der-e-coach.de
 */
export const environment = {
  production: false,
  environment: 'test',
  /**
   * In test the api server is on the same host
   */
  apiServerUrl: '',
  contactFormEmail: undefined,
  contactFormFirstname: undefined,
  contactFormLastname: undefined,
  contactFormSubject: undefined,
  contactFormSalutation: undefined,
  contactFormContent: undefined
};
