import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DtoContactRequest, DtoFormData } from '@common';
import { ContactService } from 'curriculum-site/src/app/core/services/contact.service';
import { DtoContactFormData, FormDataService } from 'curriculum-site/src/app/core/services/form-data.service';
import { environment } from 'curriculum-site/src/environments/environment';
import { catchError, map, Observable, shareReplay, Subject, takeUntil, throwError } from 'rxjs';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit, OnDestroy {

  //#region public properties -------------------------------------------------
  private readonly destroyed: Subject<void>;
  private readonly contactService: ContactService;
  private readonly router: Router;
  private readonly formDataService: FormDataService;
  //#endregion

  //#region public properties -------------------------------------------------
  public formGroup: UntypedFormGroup;
  public salutations: Array<string>;
  public isHandset: Observable<boolean>;
  public mailIsAvailable?: boolean | undefined;
  public error?: string;
  public formData!: DtoContactFormData;
  //#endregion

  //#region getters -----------------------------------------------------------
  public get salutationValues(): Array<string> {
    return this.formData?.salutationValues as Array<string>;
  }
  //#endregion
  //#region form controls -----------------------------------------------------
  public senderControl: UntypedFormControl;
  public senderRepeatControl: UntypedFormControl;
  public salutationControl: UntypedFormControl;
  public contentControl: UntypedFormControl;
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  constructor(
    breakpointObserver: BreakpointObserver,
    formBuilder: UntypedFormBuilder,
    router: Router,
    contactService: ContactService,
    formDataService: FormDataService) {
    this.contactService = contactService;
    this.formDataService = formDataService;
    this.router = router;
    this.error = undefined;
    this.destroyed = new Subject<void>();
    this.senderControl = new FormControl<string | undefined>(environment.contactFormEmail, [Validators.required, Validators.email]);
    this.senderRepeatControl = new FormControl<string | undefined>(environment.contactFormEmail, [Validators.required, Validators.email]);
    this.salutationControl = new FormControl<string | undefined>(environment.contactFormSalutation, Validators.required);
    this.contentControl = new FormControl<string | undefined>(environment.contactFormContent, [Validators.required, Validators.minLength(50)]);

    this.formGroup = formBuilder.group({
      sender: this.senderControl,
      senderRepeat: this.senderRepeatControl,
      salutation: this.salutationControl,
      firstName: new FormControl<string | undefined>(environment.contactFormFirstname),
      lastName: new FormControl<string | undefined>(environment.contactFormLastname),
      perDu: new FormControl<boolean | undefined>(false),
      subject: new FormControl<string | undefined>(environment.contactFormSubject),
      content: this.contentControl,
      phoneNumber: new FormControl<string | undefined>(undefined)
    });
    // { validators: []});
    this.formGroup.addValidators(this.matchEmails)
    this.mailIsAvailable = undefined;
    this.salutations = new Array<string>(
      "Herr",
      "Frau",
      "ohne"
    );
    this.isHandset = breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.XSmall])
      .pipe(
        takeUntil(this.destroyed),
        map((result: BreakpointState) => result.matches),
        shareReplay()
      );
  }

  ngOnInit(): void {
    this.contactService
      .checkMailStatus()
      .subscribe((isAvailable: boolean) => this.mailIsAvailable = isAvailable);
    this.formDataService
      .contactFormData
      .subscribe((data: DtoContactFormData) => this.formData = data);
    this.formDataService.loadContactFormData().subscribe((data: DtoFormData) => this.formData = data.data);
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
  //#endregion

  //#region UI triggered methods ----------------------------------------------
  public clear(): void {
    Object.keys(this.formGroup.controls)
      .forEach((key: string) => this.formGroup.controls[key].reset(undefined));
    this.error = undefined;
  }

  public send(): void {
    const request: DtoContactRequest = {
      sender: this.formGroup.controls['sender'].value,
      senderRepeat: this.formGroup.controls['senderRepeat'].value,
      salutation: this.formGroup.controls['salutation'].value,
      firstName: this.formGroup.controls['firstName'].value,
      lastName: this.formGroup.controls['lastName'].value,
      subject: this.formGroup.controls['subject'].value,
      content: this.formGroup.controls['content'].value,
      // phoneNumber: 'phoneNumber',
      perDu: this.formGroup.controls['perDu'].value
    }
    this.contactService
      .sendMail(request)
      .pipe(
        catchError((error: any) => {
          this.setError()
          return throwError(() => new Error(''));
        })
      )
      .subscribe((response: number) => {
        if (response < 400) {
          this.error = undefined;
          this.router.navigate(['page'], { queryParams: { content: 'more/contact-request-sent' } });
        } else {
          this.setError()
        }
      });
  }

  private setError(): void {
    this.error = this.formDataService.processErrorMessage;
  }

  private matchEmails(control: AbstractControl): ValidationErrors | null {
    const sender = control.get('sender')?.value;
    const repeat = control.get('senderRepeat')?.value;
    if (sender != repeat) {
      return { 'noMatch': true }
    }
    return null;
  }
  //#endregion
}
