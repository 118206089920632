import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements AfterViewInit, OnDestroy {

  //#region @viewchild --------------------------------------------------------
  @ViewChild('drawer', { static: false }) sidenav!: MatSidenav;
  //#endregion

  //#region private properties ------------------------------------------------
  private breakpointObserver: BreakpointObserver;
  private destroyed: Subject<void>;
  private disableClose = true;
  private overlayContainer: OverlayContainer;
  private router: Router;
  //#endregion

  //#region public properties -------------------------------------------------
  public themeColor: 'primary' | 'accent' | 'warn' = 'primary'; // ? notice this
  public isDark = false; // ? notice this
  public isHandset$: Observable<boolean>;
  public menuDivClass: string;
  public buttonDivClass: string;
  public backgroundSize: string;
  //#endregion

  //#region Constructor & C° ---------------------------------------------------
  public constructor(
    breakpointObserver: BreakpointObserver,
    overlayContainer: OverlayContainer,
    router: Router) {
    this.breakpointObserver = breakpointObserver;
    this.overlayContainer = overlayContainer;
    this.router = router;
    this.destroyed = new Subject<void>();
    this.disableClose = true;

    this.isHandset$ = this.breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.XSmall])
      .pipe(
        takeUntil(this.destroyed),
        map(result => {
          this.backgroundSize = result.matches ? '200px' : '250px'
          this.menuDivClass = result.matches ? 'menu-div-62' : 'menu-div-70';
          if (this.sidenav) {
            this.sidenav.disableClose = !result.matches;
          }
          this.disableClose = !result.matches;
          return result.matches;
        }),
        shareReplay()
      );
    this.menuDivClass = 'menu-div-70';
    this.backgroundSize = '250px';
    this.buttonDivClass = 'button-div-70'
  }

  ngAfterViewInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.sidenav && !this.sidenav.disableClose && !this.disableClose) {
          this.sidenav.close();
        }
      })
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
  //#endregion


  //#region UI triggered methods ----------------------------------------------
  public toggleTheme(): void {
    this.isDark = !this.isDark;
    if (this.isDark) {
      this.overlayContainer.getContainerElement().classList.add('dark-theme');
    } else {
      this.overlayContainer
        .getContainerElement()
        .classList.remove('dark-theme');
    }
  }
  //#endregion
}
