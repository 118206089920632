import { DtoGitInfo } from "./dto-git-info";
import { DtoQuotation } from "./dto-quotation";
import { DtoWordDefinition } from "./dto-word-definition";

export interface DtoContentBlock {
  sequence: number;
  title: string;
  wordDefinition?: DtoWordDefinition;
  content: string;
  // variables?: boolean;
  form?: string;
  quotation?: DtoQuotation;
  gitInfo?: DtoGitInfo;
}