
export * from './dto/dto-api-info';
export * from './dto/dto-contact-request';
export * from './dto/dto-content-block';
export * from './dto/dto-form-data';
export * from './dto/dto-git-info';
export * from './dto/dto-mail-service-status-response';
export * from './dto/dto-menu';
export * from './dto/dto-page';
export * from './dto/dto-ping';
export * from './dto/dto-quotation';
export * from './dto/dto-supported-languages';
export * from './dto/dto-word-definition';

export * from './types/constants';
export * from './types/form-data-types';
export * from './types/language.enum';
// export const defaultLanguage = Language.de;