import { Component, OnInit } from '@angular/core';
import { DtoFormData } from '@common';

import { CookiesService } from 'curriculum-site/src/app/core/services/cookies.service';
import { DtoClearCookieFormData, FormDataService } from 'curriculum-site/src/app/core/services/form-data.service';

@Component({
  selector: 'app-clear-cookie-form',
  templateUrl: './clear-cookie-form.component.html',
  styleUrls: ['./clear-cookie-form.component.scss']
})
export class ClearCookieFormComponent implements OnInit {

  //#region private properties ------------------------------------------------
  private readonly cookieService: CookiesService;
  private readonly formDataService: FormDataService;
  //#endregion

  //#region public properties -------------------------------------------------
  public hasCookie: boolean;
  public formData: DtoClearCookieFormData | undefined;
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  constructor(cookieService: CookiesService, formDataService: FormDataService) {
    this.cookieService = cookieService;
    this.formDataService = formDataService;
    this.hasCookie = false;
  }

  ngOnInit(): void {
    this.formDataService.clearCookieFormData.subscribe((data: DtoClearCookieFormData) => this.formData = data);
    this.cookieService.usingCookiesChanged.subscribe((value: boolean) => this.hasCookie = value);
    this.formDataService.loadClearCookieFormData().subscribe((data: DtoFormData) => this.formData = data.data);
  }
  //#endregion

  //#region UI triggered methods ----------------------------------------------
  public clear(): void {
    this.cookieService.deleteCookies(this.formData?.afterClearMessage as string);
  }
  //#endregion
}
