import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClearCookieFormFields, ContactFormFields, CookieDialogFields, DtoFormData, FormName, Language } from '@common';
import { Subject, from, Observable } from 'rxjs';
import { I18nService } from './i18n.service';

export type DtoClearCookieFormData = Record<ClearCookieFormFields, string | Array<string>>;
export type DtoContactFormData = Record<ContactFormFields, string | Array<string>>;
export type DtoConfirmationDialogData = Record<CookieDialogFields, string | Array<string>>;

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  //#region private properties ------------------------------------------------
  private readonly httpClient: HttpClient;
  private readonly contactForm: Subject<DtoContactFormData>;
  private readonly clearCookieForm: Subject<DtoClearCookieFormData>;
  private readonly formDataRoot: string;
  private _processErrorMessage: string;
  //#endregion

  //#region public properties -------------------------------------------------
  public contactFormData: Observable<DtoContactFormData>;
  public clearCookieFormData: Observable<DtoClearCookieFormData>;
  public get processErrorMessage(): string {
    return this._processErrorMessage;
  }
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  constructor(httpClient: HttpClient, i18nService: I18nService) {
    this.httpClient = httpClient;
    this.contactForm = new Subject<DtoContactFormData>();
    this.clearCookieForm = new Subject<DtoClearCookieFormData>();
    this.contactFormData = from(this.contactForm);
    this.clearCookieFormData = from(this.clearCookieForm);

    this._processErrorMessage = '';
    this.formDataRoot = 'api/site/forms/';
    i18nService.languageChanged.subscribe((language: Language) => {
      this.loadContactFormData().subscribe((data: DtoFormData) => this.contactForm.next(data.data));
      this.loadClearCookieFormData().subscribe((data: DtoFormData) => this.clearCookieForm.next(data.data));
      this._processErrorMessage = language === Language.de ?
        'Das hat leider nicht geklappt.<br>Versuchen Sie es spater bitte nochmal.' :
        'That didn\'t work out as expected.<br>Please try again later.'
    })
   }
   //#endregion

   //#region public methods ---------------------------------------------------
  public loadCookieConfirmationData(): Observable<DtoFormData> {
    return this.httpClient.get<DtoFormData>(this.getFormDataUrl('cookie-dialog'));
   }

   public loadContactFormData(): Observable<DtoFormData> {
     return this.httpClient.get<DtoFormData>(this.getFormDataUrl('contact-form'));
   }

   public loadClearCookieFormData(): Observable<DtoFormData> {
     return this.httpClient.get<DtoFormData>(this.getFormDataUrl('clear-cookie-form'));
   }
   //#endregion

   //#region private methods --------------------------------------------------
   private getFormDataUrl(formName: FormName): string {
     return `${this.formDataRoot}${formName}`;
   }
   //#endregion
}
