<hr>
<div mat-dialog-content class="cookie-form-container">
  <ng-template #nocookie>
    <p>
      {{formData?.noCookieText}}
    </p>
  </ng-template>
  <ng-container *ngIf="hasCookie; else nocookie">
    <p>
      {{formData?.cookieText}}
    </p>
    <div mat-dialog-actions>
      <button mat-stroked-button (click)="clear()">{{formData?.clearButtonLabel}}</button>
    </div>
  </ng-container>
</div>