<mat-card>
  <mat-card-content class="menu-mat-card-content">
    <ng-container *ngFor="let item of menuItems">
      <app-menu-item [item]="item"></app-menu-item>
    </ng-container>
    <div class="copyright">&copy; Johan Bouduin (2017-{{currentYear}})</div>
    <div *ngIf="apiVersion" class="copyright">API Version: v{{apiVersion}}</div>
    <div *ngIf="apiEnvironment" class="copyright error"><b>{{apiEnvironment}}</b></div>
    <div class="copyright">Client Version: v{{clientVersion}}</div>
    <div *ngIf="clientEnvironment" class="copyright error"><b>{{clientEnvironment}}</b></div>
  </mat-card-content>
</mat-card>