<ng-container *ngIf="supportedLanguages && supportedLanguages.length > 1">
  <button mat-icon-button [matMenuTriggerFor]="languageMenu">
    <mat-icon>language</mat-icon>
  </button>
  <mat-menu #languageMenu="matMenu">
    <button mat-menu-item *ngFor="let language of supportedLanguages" (click)="setLanguage(language)"
      [disabled]="isCurrentLanguage(language)">
        <mat-icon *ngIf="language.language === currentLanguage" class="language-icon" [style.color]="getColor()">check</mat-icon>
        <mat-icon *ngIf="language.language !== currentLanguage" class="language-icon"></mat-icon>
      <span>
        {{language.label[currentLanguage]}}
      </span>
    </button>

  </mat-menu>
</ng-container>