<ng-template #hasChildren>
  <span class="with-children" *ngIf="!item.isHidden">{{ item.label }}</span>
  <ng-container *ngFor="let child of item.children">
    <app-menu-item [item]="child"></app-menu-item>
  </ng-container>
  <hr>
</ng-template>

<div *ngIf="!item.children || item.children.length === 0; else hasChildren">
  <button mat-button (click)="menuClick(item.routerLink, { content: item.queryParams })">
    <mat-icon>{{item.icon}}</mat-icon>
    <span class="aligned-with-icon">{{item.label}}</span>
  </button>
</div>