<div [class.dark-theme]="isDark">

  <mat-toolbar [color]="themeColor">
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
      *ngIf="isHandset$ | async">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <div class="toolbar-container">
      <app-language-selector class="float-right"></app-language-selector>
    </div>
  </mat-toolbar>

  <div class="app-container">
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <div [class]="menuDivClass">
          <app-menu></app-menu>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>

</div>