import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DtoContactRequest, DtoMailServiceStatusResponse } from "@common";
import { from, map, Observable } from "rxjs";

interface SendResult {
  status: number;
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  //#region private properties ------------------------------------------------
  private httpClient: HttpClient;

  //#endregion

  //#region Constructor -------------------------------------------------------
  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }
  //#endregion

  //#region public methods ----------------------------------------------------
  public checkMailStatus(): Observable<boolean> {
    return this.httpClient
      .get<DtoMailServiceStatusResponse>('api/system/mail-service-status')
      .pipe(
        map((result: DtoMailServiceStatusResponse) => result.available)
      );
  }

  public sendMail(request: DtoContactRequest): Observable<number> {
    if (request.phoneNumber) {
      return from([200]);
    }
    return this.httpClient
      .post<SendResult>('api/system/contact', request)
      .pipe(
        map((response: SendResult) => response.status)
      );
  }
  //#endregion
}