export interface DtoContactRequest {
  sender: string;
  senderRepeat: string;
  salutation: string;
  firstName: string;
  lastName: string;
  perDu: boolean;
  subject: string;
  content: string;
  phoneNumber?: string;
}